import {
  A,
  cache,
  createAsync,
  useIsRouting,
  useNavigate,
  useParams,
} from "@solidjs/router";
import {
  Accessor,
  createMemo,
  createSignal,
  For,
  onMount,
  Show,
} from "solid-js";
import { budgetInputBG, editIcon } from "~/assets/assets";
import ClientOnlyComponent, {
  ClientComponent,
} from "~/client_only_components/client_component";
import { Stepper } from "~/components/pluto/stepper";
import { PaymentLoader } from "~/screens/payment_options/payment_loader";
import {
  getGiftById,
  initiatePayment,
  updateGiftBudget,
} from "~/server/apis/client_apis";
import {
  getPlutoGiftDetailsRouteData,
  PlutoGiftDetailsRouteData,
} from "~/server/data/pluto_gift_details_route_data";
import { goBack, HubbleRoute, pushPage } from "~/shared_states/modal";
import { ThreeDotLoader } from "~/widgets/button";
import { PhosphorIcon } from "~/widgets/icons";
import { DottedLoader } from "~/widgets/loader";

const getGiftDetailsRouteData$C = cache(
  getPlutoGiftDetailsRouteData,
  "pluto_gift_details"
);

export default function PlutoGiftBudget() {
  const params = useParams();
  const navigate = useNavigate();
  const isRouting = useIsRouting();

  const [amount, setAmount] = createSignal("");
  const [error, setError] = createSignal(false);
  const [isLoading, setIsLoading] = createSignal(false);

  let inputRef: HTMLInputElement | undefined;

  const routeData: Accessor<PlutoGiftDetailsRouteData | undefined> =
    createAsync(() => getGiftDetailsRouteData$C(params.giftId), {
      deferStream: true,
    });

  const [latestGiftCardDetails, setLatestGiftCardDetails] = createSignal(
    routeData()?.giftDetails
  );

  onMount(async () => {
    const giftDetails = await getGiftById(params.giftId);
    setLatestGiftCardDetails(giftDetails);
  });

  const giftCardDetails = createMemo(() => {
    return latestGiftCardDetails() ?? routeData()?.giftDetails;
  });

  const navigateToEdit = (side: string) => {
    if (latestGiftCardDetails()) {
      navigate(`/gifts/${params.giftId}/edit`, {
        state: { side },
      });
    }
  };

  const validateAmount = (value: string) => {
    const numValue = parseInt(value, 10);
    if (isNaN(numValue) || numValue < 1000 || numValue > 50000) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const handleInput = (e: Event) => {
    const value = (e.target as HTMLInputElement).value;
    if (/^\d*$/.test(value)) {
      setAmount(value);
      validateAmount(value);
    }
  };

  onMount(() => {
    inputRef?.focus();
  });

  const setGiftBudget = async () => {
    setIsLoading(true);
    try {
      const response = await updateGiftBudget(params.giftId, Number(amount()));
      if (response.status === 200) {
        const initiatePaymentResponse = await initiatePayment(params.giftId);
        setIsLoading(false);
        if (initiatePaymentResponse.paymentReferenceId) {
          pushPage(
            () => (
              <PaymentLoader
                amount={Number(amount())}
                onPaymentComplete={() => {
                  navigate(`/gifts/${params.giftId}/share`, {
                    state: { status: "success", budget: Number(amount()) },
                  });
                }}
                onPaymentFailed={(failureReason: string) => {
                  navigate(`/gifts/${params.giftId}/share`, {
                    state: { status: "failure" },
                  });
                }}
                orderId={initiatePaymentResponse.paymentReferenceId}
                brandImageUrl=""
                reInit={() => {}}
                onClose={goBack}
              />
            ),
            {
              isModal: true,
              disableCloseButton: true,
              preventClose: true,
              enterAnimation: false,
            },
            HubbleRoute.PAYMENT_LOADER
          );
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleScrollUp = () => {
    if (inputRef) {
      inputRef.scrollIntoView({ behavior: "smooth", block: "center" });

      setTimeout(() => {
        inputRef?.focus();
      }, 300);
    }
  };

  return (
    <>
      <ClientOnlyComponent component={ClientComponent.ModalHost} />
      <div class="flex h-full w-full flex-col overflow-y-auto text-white md:w-[800px]">
        <div class="relative flex min-h-14 items-center justify-center px-4 py-2">
          <A
            href={`/gifts/${params.giftId}/disclaimer`}
            class="absolute left-4 top-4"
          >
            <PhosphorIcon
              name="arrow-left"
              fontSize={20}
              size="thin"
              class="cursor-pointer text-white"
            />
          </A>
          <p class="text-center text-smallBold uppercase tracking-[1.1px] text-white">
            Gift for {giftCardDetails()?.content.text.occasion.recipientName}
          </p>
        </div>
        {/* <Stepper activeStep={1} /> */}
        <div class="ml-20 flex w-auto flex-row">
          <div class="group flex-1 shrink basis-0">
            <div class="inline-flex min-h-7 w-full min-w-7 items-center align-middle">
              <span class="flex size-6 shrink-0 items-center justify-center rounded-full bg-[#00AF54]">
                <PhosphorIcon
                  name="check"
                  fontSize={16}
                  size="bold"
                  class="text-white"
                />
              </span>

              <div class="h-px w-full flex-1 bg-white group-last:hidden"></div>
            </div>
            <div class="-ml-2 mt-1">
              <span class="text-medium text-[#00AF54]">Created</span>
            </div>
          </div>

          <div class="group flex-1 shrink basis-0">
            <div class="inline-flex min-h-7 w-full min-w-7 items-center align-middle">
              <span class="flex size-6 shrink-0 items-center justify-center rounded-full bg-white text-f12Bold text-textDark ">
                2
              </span>
              <div class="h-px w-full flex-1 bg-basePrimaryDark group-last:hidden"></div>
            </div>
            <div class="-ml-2 mt-1">
              <span class="text-medium text-white">Top-up</span>
            </div>
          </div>

          <div class="group flex-1 shrink basis-0">
            <div class="inline-flex min-h-7 w-full min-w-7 items-center align-middle">
              <span class="flex size-6 shrink-0 items-center justify-center rounded-full bg-basePrimaryDark text-f12Bold text-white ">
                3
              </span>
              <div class="h-px w-full flex-1 bg-basePrimaryDark group-last:hidden"></div>
            </div>
            <div class="-ml-2 mt-1">
              <span class="text-medium text-textNormal">Share</span>
            </div>
          </div>
        </div>
        <div>
          <div class="my-6">
            <h1 class="text-center text-[28px] font-semibold">
              Top up gift balance
            </h1>
            <p class="text-center text-medium text-textNormal">
              After payment, you'll receive a 🔗shareable gift link
            </p>
          </div>
          <div class="relative">
            <img class="absolute -mt-8" src={budgetInputBG} alt="" />
            <div class="relative mx-auto  h-[195px] p-4">
              <div class="animated-border-box-glow"></div>
              <div class=" animated-border-box rounded-[20px] border border-basePrimaryDark p-4">
                <div class="relative">
                  <input
                    type="number"
                    ref={(el) => (inputRef = el)}
                    inputMode="numeric"
                    class={`block h-[44px] w-full rounded-lg border ${
                      error() ? "border-errorDark" : "border-white"
                    } bg-transparent p-3 ps-10 text-bold placeholder:text-medium placeholder:text-textNormal focus:z-10 focus:border-white focus:ring-white disabled:pointer-events-none disabled:opacity-50`}
                    placeholder="Enter amount"
                    value={amount()}
                    onInput={handleInput}
                  />
                  <div class="pointer-events-none absolute inset-y-0 start-0 z-20 flex items-center ps-4">
                    <p class="text-normal">₹</p>
                  </div>
                </div>
                <Show when={error()}>
                  <p class="mt-1 text-medium text-errorDark">
                    Anything between ₹1,000 - ₹50,000
                  </p>
                </Show>
                <button
                  class="my-5 flex h-[44px] w-full items-center justify-center rounded-[41px] bg-baseTertiaryMedium px-3  text-buttonMedium font-bold text-textDark disabled:bg-basePrimaryMedium disabled:text-baseSecondaryMedium"
                  disabled={error() || !amount() || isLoading()}
                  onClick={setGiftBudget}
                >
                  {isLoading() ? (
                    <ThreeDotLoader color="#000" />
                  ) : (
                    <>Proceed to pay</>
                  )}
                </button>
                <div class="flex items-center justify-center gap-1 self-stretch">
                  <div class=" text-medium text-textNormal">
                    Safe & secure payment
                  </div>
                  <img
                    class="h-4 w-20"
                    src={
                      "https://gullak-assets.s3.ap-south-1.amazonaws.com/icons/razorpay-trust-marker.png"
                    }
                    alt="Secure payments"
                  />
                </div>
              </div>
            </div>
            <div class="my-6 flex  cursor-pointer items-center justify-center gap-1 p-4">
              <PhosphorIcon
                name="question"
                fontSize={20}
                size="bold"
                class={` text-baseTertiaryLight`}
              />
              <A
                href={`/gifts/${params.giftId}/disclaimer`}
                class="z-10 text-mediumBold text-white underline"
              >
                How it works?
              </A>
            </div>
          </div>
        </div>
        <div class="p-4">
          <Show
            when={!isRouting() || !giftCardDetails()}
            fallback={<DottedLoader color="#999" />}
          >
            <p class="mb-6 text-h5 font-normal text-textNormal">Gift details</p>
            <h1 class="text-[28px] font-semibold">
              {giftCardDetails()?.title}
            </h1>
            <div class="mt-12 flex flex-col gap-6">
              <div class="flex items-center gap-2">
                <p class="text-nowrap text-smallBold uppercase tracking-[1.1px]">
                  The gift
                </p>
                <hr class="w-full border border-t border-basePrimaryMedium" />
              </div>
              <img
                class="h-28 w-48 rounded-md"
                src={
                  giftCardDetails()?.content.images.occasionImages[0].imageUrl
                }
                alt={giftCardDetails()?.title}
              />
              <p class="text-normal text-baseSecondaryLight">
                This gift features thoughtful and unforgettable experiences
                curated by Pluto, based on your inputs, for you to share with{" "}
                {giftCardDetails()?.content.text.occasion.recipientName}. It
                includes:
              </p>
              <div class="flex flex-col gap-2.5">
                <For each={giftCardDetails()?.content.text.activitySlots}>
                  {(activity, index) => {
                    return (
                      <div class={`flex items-center gap-2.5`}>
                        <img
                          src={
                            giftCardDetails()?.brandDetails.find(
                              (brand) =>
                                brand.brandKey ===
                                activity.activities[
                                  Number(
                                    giftCardDetails()?.content.selection
                                      .activities[index() + 1]
                                  ) - 1
                                ].brandKey
                            )?.logoUrl
                          }
                          alt=""
                          class="h-10 w-10 rounded-full"
                        />
                        <p class={`text-normal text-white`}>
                          {
                            activity.activities[
                              Number(
                                giftCardDetails()?.content.selection.activities[
                                  index() + 1
                                ]
                              ) - 1
                            ]?.description
                          }
                        </p>
                      </div>
                    );
                  }}
                </For>
              </div>
              <div class="flex gap-1">
                <img src={editIcon} alt="edit icon" class="h-5 w-5" />
                <button
                  onClick={() => navigateToEdit("back")}
                  class="cursor-pointer text-subtitleSmall underline"
                >
                  Edit gift
                </button>
              </div>
            </div>
            <div class="mt-12 flex flex-col gap-6">
              <div class="flex items-center gap-2">
                <p class="text-nowrap text-smallBold uppercase tracking-[1.1px]">
                  The message
                </p>
                <hr class="w-full border border-t border-basePrimaryMedium" />
              </div>
              <img
                class="h-28 w-32 rounded-md"
                src={
                  giftCardDetails()?.content.images.activityImages[0].imageUrl
                }
                alt={giftCardDetails()?.title}
              />
              <p class="text-normal text-baseSecondaryLight">
                {giftCardDetails()?.content.text.occasion.occasionTitle}
              </p>
              <p class="text-normal text-baseSecondaryLight">
                {giftCardDetails()?.content.text.occasion.wishMessage}
              </p>
              <div class="flex gap-1">
                <img src={editIcon} alt="edit icon" class="h-5 w-5" />
                <button
                  onClick={() => navigateToEdit("front")}
                  class="cursor-pointer text-subtitleSmall underline"
                >
                  Edit message
                </button>
              </div>
            </div>
          </Show>
        </div>
        <div class="flex flex-col gap-3 border-t border-basePrimaryDark p-4">
          <button class="text-buttonMedium underline">
            Receiver’s preview
          </button>
          <button
            onClick={handleScrollUp}
            class="flex h-[44px] w-full items-center justify-center rounded-[41px] bg-baseTertiaryMedium px-3"
          >
            <p class="text-buttonMedium font-bold text-textDark">
              Next : Top up gift balance
            </p>
          </button>
        </div>
      </div>
    </>
  );
}
